/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as t } from "../../chunks/tslib.es6.js";
import { geometryTypes as e } from "../../geometry.js";
import r from "../../TimeExtent.js";
import { JSONMap as o } from "../../core/jsonMap.js";
import { JSONSupport as i } from "../../core/JSONSupport.js";
import { clone as s } from "../../core/lang.js";
import { property as n } from "../../core/accessorSupport/decorators/property.js";
import { cast as a } from "../../core/accessorSupport/decorators/cast.js";
import { enumeration as l } from "../../core/accessorSupport/decorators/enumeration.js";
import { subclass as p } from "../../core/accessorSupport/decorators/subclass.js";
import { writer as u } from "../../core/accessorSupport/decorators/writer.js";
import { ensureClass as m } from "../../core/accessorSupport/ensureType.js";
import { fromJSON as c } from "../../geometry/support/jsonUtils.js";
import { DataLayerSource as d } from "../../layers/support/source/DataLayerSource.js";
import y from "./FullTextSearch.js";
import h from "./QuantizationParameters.js";
import { spatialRelKebabDict as f } from "./spatialRelationships.js";
import j from "./StatisticDefinition.js";
import w from "../../geometry/SpatialReference.js";
import v from "../../geometry/Point.js";
var S;
const g = new o({
  esriSRUnit_Meter: "meters",
  esriSRUnit_Kilometer: "kilometers",
  esriSRUnit_Foot: "feet",
  esriSRUnit_StatuteMile: "miles",
  esriSRUnit_NauticalMile: "nautical-miles",
  esriSRUnit_USNauticalMile: "us-nautical-miles"
});
let R = S = class extends i {
  static from(t) {
    return m(S, t);
  }
  constructor(t) {
    super(t), this.aggregateIds = null, this.cacheHint = void 0, this.compactGeometryEnabled = !1, this.datumTransformation = null, this.defaultSpatialReferenceEnabled = !1, this.distance = void 0, this.dynamicDataSource = void 0, this.formatOf3DObjects = null, this.fullText = null, this.gdbVersion = null, this.geometry = null, this.geometryPrecision = void 0, this.groupByFieldsForStatistics = null, this.having = null, this.historicMoment = null, this.maxAllowableOffset = void 0, this.maxRecordCountFactor = 1, this.multipatchOption = null, this.num = void 0, this.objectIds = null, this.orderByFields = null, this.outFields = null, this.outSpatialReference = null, this.outStatistics = null, this.parameterValues = null, this.pixelSize = null, this.quantizationParameters = null, this.rangeValues = null, this.relationParameter = null, this.resultType = null, this.returnCentroid = !1, this.returnDistinctValues = !1, this.returnExceededLimitFeatures = !0, this.returnGeometry = !1, this.returnQueryGeometry = !1, this.returnM = void 0, this.returnZ = void 0, this.sourceSpatialReference = null, this.spatialRelationship = "intersects", this.start = void 0, this.sqlFormat = null, this.text = null, this.timeExtent = null, this.timeReferenceUnknownClient = !1, this.units = null, this.where = null;
  }
  castDatumTransformation(t) {
    return "number" == typeof t || "object" == typeof t ? t : null;
  }
  writeHistoricMoment(t, e) {
    e.historicMoment = t && t.getTime();
  }
  writeParameterValues(t, e) {
    if (t) {
      const r = {};
      for (const e in t) {
        const o = t[e];
        Array.isArray(o) ? r[e] = o.map(t => t instanceof Date ? t.getTime() : t) : o instanceof Date ? r[e] = o.getTime() : r[e] = o;
      }
      e.parameterValues = r;
    }
  }
  writeStart(t, e) {
    e.resultOffset = this.start, e.resultRecordCount = this.num || 10, e.where = "1=1";
  }
  writeWhere(t, e) {
    e.where = t || "1=1";
  }
  clone() {
    return new S(s({
      aggregateIds: this.aggregateIds,
      cacheHint: this.cacheHint,
      compactGeometryEnabled: this.compactGeometryEnabled,
      datumTransformation: this.datumTransformation,
      defaultSpatialReferenceEnabled: this.defaultSpatialReferenceEnabled,
      distance: this.distance,
      fullText: this.fullText,
      formatOf3DObjects: this.formatOf3DObjects,
      gdbVersion: this.gdbVersion,
      geometry: this.geometry,
      geometryPrecision: this.geometryPrecision,
      groupByFieldsForStatistics: this.groupByFieldsForStatistics,
      having: this.having,
      historicMoment: null != this.historicMoment ? new Date(this.historicMoment.getTime()) : null,
      maxAllowableOffset: this.maxAllowableOffset,
      maxRecordCountFactor: this.maxRecordCountFactor,
      multipatchOption: this.multipatchOption,
      num: this.num,
      objectIds: this.objectIds,
      orderByFields: this.orderByFields,
      outFields: this.outFields,
      outSpatialReference: this.outSpatialReference,
      outStatistics: this.outStatistics,
      parameterValues: this.parameterValues,
      pixelSize: this.pixelSize,
      quantizationParameters: this.quantizationParameters,
      rangeValues: this.rangeValues,
      relationParameter: this.relationParameter,
      resultType: this.resultType,
      returnDistinctValues: this.returnDistinctValues,
      returnGeometry: this.returnGeometry,
      returnCentroid: this.returnCentroid,
      returnExceededLimitFeatures: this.returnExceededLimitFeatures,
      returnQueryGeometry: this.returnQueryGeometry,
      returnM: this.returnM,
      returnZ: this.returnZ,
      dynamicDataSource: this.dynamicDataSource,
      sourceSpatialReference: this.sourceSpatialReference,
      spatialRelationship: this.spatialRelationship,
      start: this.start,
      sqlFormat: this.sqlFormat,
      text: this.text,
      timeExtent: this.timeExtent,
      timeReferenceUnknownClient: this.timeReferenceUnknownClient,
      units: this.units,
      where: this.where
    }));
  }
};
R.MAX_MAX_RECORD_COUNT_FACTOR = 5, t([n({
  json: {
    write: !0
  }
})], R.prototype, "aggregateIds", void 0), t([n({
  type: Boolean,
  json: {
    write: !0
  }
})], R.prototype, "cacheHint", void 0), t([n({
  type: Boolean,
  json: {
    default: !1,
    write: !0
  }
})], R.prototype, "compactGeometryEnabled", void 0), t([n({
  json: {
    write: !0
  }
})], R.prototype, "datumTransformation", void 0), t([a("datumTransformation")], R.prototype, "castDatumTransformation", null), t([n({
  type: Boolean,
  json: {
    default: !1,
    write: !0
  }
})], R.prototype, "defaultSpatialReferenceEnabled", void 0), t([n({
  type: Number,
  json: {
    write: {
      overridePolicy: t => ({
        enabled: t > 0
      })
    }
  }
})], R.prototype, "distance", void 0), t([n({
  type: d,
  json: {
    write: !0
  }
})], R.prototype, "dynamicDataSource", void 0), t([n({
  type: String,
  json: {
    write: !0
  }
})], R.prototype, "formatOf3DObjects", void 0), t([n({
  type: [y],
  json: {
    write: {
      enabled: !0,
      overridePolicy() {
        return {
          enabled: null != this.fullText && this.fullText.length > 0
        };
      }
    }
  }
})], R.prototype, "fullText", void 0), t([n({
  type: String,
  json: {
    write: !0
  }
})], R.prototype, "gdbVersion", void 0), t([n({
  types: e,
  json: {
    read: c,
    write: !0
  }
})], R.prototype, "geometry", void 0), t([n({
  type: Number,
  json: {
    write: !0
  }
})], R.prototype, "geometryPrecision", void 0), t([n({
  type: [String],
  json: {
    write: !0
  }
})], R.prototype, "groupByFieldsForStatistics", void 0), t([n({
  type: String,
  json: {
    write: !0
  }
})], R.prototype, "having", void 0), t([n({
  type: Date
})], R.prototype, "historicMoment", void 0), t([u("historicMoment")], R.prototype, "writeHistoricMoment", null), t([n({
  type: Number,
  json: {
    write: !0
  }
})], R.prototype, "maxAllowableOffset", void 0), t([n({
  type: Number,
  cast: t => t < 1 ? 1 : t > R.MAX_MAX_RECORD_COUNT_FACTOR ? R.MAX_MAX_RECORD_COUNT_FACTOR : t,
  json: {
    write: {
      overridePolicy: t => ({
        enabled: t > 1
      })
    }
  }
})], R.prototype, "maxRecordCountFactor", void 0), t([n({
  type: ["xyFootprint"],
  json: {
    write: !0
  }
})], R.prototype, "multipatchOption", void 0), t([n({
  type: Number,
  json: {
    read: {
      source: "resultRecordCount"
    }
  }
})], R.prototype, "num", void 0), t([n({
  json: {
    write: !0
  }
})], R.prototype, "objectIds", void 0), t([n({
  type: [String],
  json: {
    write: !0
  }
})], R.prototype, "orderByFields", void 0), t([n({
  type: [String],
  json: {
    write: !0
  }
})], R.prototype, "outFields", void 0), t([n({
  type: w,
  json: {
    name: "outSR",
    write: !0
  }
})], R.prototype, "outSpatialReference", void 0), t([n({
  type: [j],
  json: {
    write: {
      enabled: !0,
      overridePolicy() {
        return {
          enabled: null != this.outStatistics && this.outStatistics.length > 0
        };
      }
    }
  }
})], R.prototype, "outStatistics", void 0), t([n({
  json: {
    write: !0
  }
})], R.prototype, "parameterValues", void 0), t([u("parameterValues")], R.prototype, "writeParameterValues", null), t([n({
  type: v,
  json: {
    write: !0
  }
})], R.prototype, "pixelSize", void 0), t([n({
  type: h,
  json: {
    write: !0
  }
})], R.prototype, "quantizationParameters", void 0), t([n({
  type: [Object],
  json: {
    write: !0
  }
})], R.prototype, "rangeValues", void 0), t([n({
  type: String,
  json: {
    read: {
      source: "relationParam"
    },
    write: {
      target: "relationParam",
      overridePolicy() {
        return {
          enabled: "relation" === this.spatialRelationship
        };
      }
    }
  }
})], R.prototype, "relationParameter", void 0), t([n({
  type: String,
  json: {
    write: !0
  }
})], R.prototype, "resultType", void 0), t([n({
  type: Boolean,
  json: {
    default: !1,
    write: !0
  }
})], R.prototype, "returnCentroid", void 0), t([n({
  type: Boolean,
  json: {
    default: !1,
    write: !0
  }
})], R.prototype, "returnDistinctValues", void 0), t([n({
  type: Boolean,
  json: {
    default: !0,
    write: !0
  }
})], R.prototype, "returnExceededLimitFeatures", void 0), t([n({
  type: Boolean,
  json: {
    write: !0
  }
})], R.prototype, "returnGeometry", void 0), t([n({
  type: Boolean,
  json: {
    default: !1,
    write: !0
  }
})], R.prototype, "returnQueryGeometry", void 0), t([n({
  type: Boolean,
  json: {
    default: !1,
    write: !0
  }
})], R.prototype, "returnM", void 0), t([n({
  type: Boolean,
  json: {
    write: {
      overridePolicy: t => ({
        enabled: t
      })
    }
  }
})], R.prototype, "returnZ", void 0), t([n({
  type: w,
  json: {
    write: !0
  }
})], R.prototype, "sourceSpatialReference", void 0), t([l(f, {
  ignoreUnknown: !1,
  name: "spatialRel"
})], R.prototype, "spatialRelationship", void 0), t([n({
  type: Number,
  json: {
    read: {
      source: "resultOffset"
    }
  }
})], R.prototype, "start", void 0), t([u("start"), u("num")], R.prototype, "writeStart", null), t([n({
  type: String,
  json: {
    write: !0
  }
})], R.prototype, "sqlFormat", void 0), t([n({
  type: String,
  json: {
    write: !0
  }
})], R.prototype, "text", void 0), t([n({
  type: r,
  json: {
    write: !0
  }
})], R.prototype, "timeExtent", void 0), t([n({
  type: Boolean,
  json: {
    default: !1,
    write: !0
  }
})], R.prototype, "timeReferenceUnknownClient", void 0), t([l(g, {
  ignoreUnknown: !1
}), n({
  json: {
    write: {
      overridePolicy(t) {
        return {
          enabled: !!t && null != this.distance && this.distance > 0
        };
      }
    }
  }
})], R.prototype, "units", void 0), t([n({
  type: String,
  json: {
    write: {
      overridePolicy(t) {
        return {
          enabled: null != t || null != this.start && this.start > 0
        };
      }
    }
  }
})], R.prototype, "where", void 0), t([u("where")], R.prototype, "writeWhere", null), R = S = t([p("esri.rest.support.Query")], R);
const b = R;
export { b as default };