/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as e } from "../../chunks/tslib.es6.js";
import "../../geometry.js";
import { JSONMap as o } from "../../core/jsonMap.js";
import { JSONSupport as t } from "../../core/JSONSupport.js";
import { clone as r } from "../../core/lang.js";
import { property as i } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import { subclass as s } from "../../core/accessorSupport/decorators/subclass.js";
import p from "../../geometry/Extent.js";
var n;
const c = new o({
  upperLeft: "upper-left",
  lowerLeft: "lower-left"
});
let m = n = class extends t {
  constructor(e) {
    super(e), this.extent = null, this.mode = "view", this.originPosition = "upper-left", this.tolerance = 1;
  }
  clone() {
    return new n(r({
      extent: this.extent,
      mode: this.mode,
      originPosition: this.originPosition,
      tolerance: this.tolerance
    }));
  }
};
e([i({
  type: p,
  json: {
    write: {
      overridePolicy() {
        return {
          enabled: "view" === this.mode
        };
      }
    }
  }
})], m.prototype, "extent", void 0), e([i({
  type: ["view", "edit"],
  json: {
    write: !0
  }
})], m.prototype, "mode", void 0), e([i({
  type: String,
  json: {
    read: c.read,
    write: c.write
  }
})], m.prototype, "originPosition", void 0), e([i({
  type: Number,
  json: {
    write: {
      overridePolicy() {
        return {
          enabled: "view" === this.mode
        };
      }
    }
  }
})], m.prototype, "tolerance", void 0), m = n = e([s("esri.rest.support.QuantizationParameters")], m);
const a = m;
export { a as default };